.planscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 25%;
  height: 80%;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  animation: unfoldIn 0.5s;
  filter: blur(0px) !important;
  border-radius: 8px;
  /* padding: 20px !important; */
}

@media (max-width: 1000px) {
  .planscreen {
    width: 70%;
  }

  input {
    width: 100% !important;
  }
}

.df {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 23px 0;
}

.sm {
  font-size: 1.1rem !important;
  color: rgb(51, 224, 60)
}

.modal__left {
  color: white;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal__right {
  color: #242424;
  background-color: #fff;
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal__para {
  font-size: 40px;
  text-align: center;
  line-height: 1.25;
  font-weight: bold;
}

.modal__list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal__title {
  color: #242424;
}

.modal__li {
  list-style: none;
  text-align: center;
  font-size: 1.3rem;
  letter-spacing: 0.5px;
  margin-left: .25rem;
}

.center {
  margin-top: 20px;
}

.discord__btn {
  height: 40px !important;
  width: 260px !important;
  border: none;
  border-radius: 8px;
  background-color: #7289da;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-top: 40px;
  cursor: pointer;
  transition: all 300ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
}



.discord__btn:hover {
  background-color: #f5f5f5;
  color: #7289da;
  border: 1px solid #7289da;
}

.modal__form {
  width: 100%;
  text-align: left;
}

.modal__btn--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.bi-x {
  font-size: 40px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  cursor: pointer;
  transition: all 300ms ease;
  color: #242424;
}

.bi-x:hover {
  transform: scale(1.1);
  color: #ffbf00;
}

.or {
  font-size: 40px;
  letter-spacing: 1px;
}

.premium__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  /* background: linear-gradient(to top, #314e75 0%, #537895 100%); */
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  border-radius: 20px;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.premium__btn {
  margin-top: 10px;
  width: 40%;
  height: 40px;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
  color: #242424;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;

  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 300ms ease;
  border-radius: 8px;
}

.premium__btn:hover {
  transform: scale(1.1);
}

.form__item--label {
  margin-bottom: 8px;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 300;
  color: #242424 !important;
}

.planscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 92%);
  color: #000;
  font-size: 25px;
  z-index: 100;
  text-align: center;
}
.planscreen-overlay-content img {
  width: 30px;
}
.planscreen-overlay-content {
  display: flex;
  gap: 10px;
}

/*Animation*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes unfoldIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

