.footer {
    position: fixed;
    bottom: 0;
    background-color: #7289d9;
    width: 100%;
    text-align: center;
    height: 30px;
    cursor: pointer !important;
}

.footer__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease;
    cursor: pointer !important;
}

.footer__wrapper:hover {
    transform: scale(1.1);
}

.footer__text {
    font-size: 1rem;
    padding: 6px 0;
    letter-spacing: 1.1px;
    color: #fff;
    font-weight: bold;
}

