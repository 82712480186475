.course__lesson {
  width: 100%;
  display: flex;
  padding: 40px 12px;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: flex-start;
}

.lesson__title {
  font-size: 40px;
  text-align: center;
  margin-top: 20px;
}

.lesson__para {
  margin-top: 8px;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.pre {
  color: gold;
  font-weight: bold;
}

.buttons__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.section__box {
  margin-left: auto;
  margin-right: auto;
  margin-top: -28px;
  padding: 10px 16px;
  border-radius: 8px;
  text-align: center;
  width: 95%;
}

.css__color {
  border: 2px solid #2965f1;
  box-shadow: 2px 2px #2965f1;
}

.css {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.ui__color {
  border: 2px solid #b229f1;
  box-shadow: 2px 2px #b229f1;
}

.ui {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

/* .sidebar:nth-child() {
  color: #b229f1 !important;
} */

.into {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.html__color {
  border: 2px solid #f06529;
  box-shadow: 2px 2px #f06529;
}

.html {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.js__color {
  border: 2px solid #f0db4f;
  box-shadow: 2px 2px #f0db4f;
}

.api__color {
  border: 2px solid #29f133;
  box-shadow: 2px 2px #29f133;
}

.hired__color {
  border: 2px solid #f5bd02;
  box-shadow: 2px 2px #f5bd02;
}

.hired {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.api {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.js {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.react__color {
  border: 2px solid #61dbfb;
  box-shadow: 2px 2px #61dbfb;
}

.react {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.technical__color {
  border: 2px solid black;
  box-shadow: 2px 2px black;
}

.technical {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

@media (max-width: 800px) {
  .buttons__container {
    flex-direction: column;
  }

  .lock__text {
    display: none;
  }
}
