.sidebar {
    position: fixed;
    left: 0;
    width: 250px;
    flex-shrink: 0;
    /* background: linear-gradient(to top, #09203f 0%, #537895 100%); */
    background-color: #f7f7f7;
    height: 100%;
    overflow: auto;
    color: #242424;
    padding: 20px;
    border-right: 1px solid #a9a9a9;
    animation: sideAnimation 400ms;
    transition: all 400ms ease;
    z-index: 100000;
    /* text-shadow: 1px 1px #ffbf00; */
}

.sidebar::-webkit-scrollbar {
    width: 3px;
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 8px;
  }
  
  .sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: transparent;
  }

@keyframes sideAnimation {
    0% {
        left: -250px;
    }
    100% {
        left: 0;
    }
}

@keyframes reversesideAnimation {
    0% {
        left: 250px;
    }
    100% {
        left: 0px;
    }
}

.sidebar__item {
    padding: .75em .5em;
    transition: all 200ms ease;
    display: block;
}

.sidebar__item:hover {
    background-color: #d8d8d8;
    border-radius: 12px;
}

.sidebar__title {
    display: flex;
    font-size: 1.2em;
    justify-content: space-between;
    padding: 20px 0;
    font-weight: bold;
    transition: all 300ms ease;
    cursor: pointer;
}

.sidebar__title:hover {
    color: #ffbf00;
    cursor: pointer;
}

.sidebar__title span i {
    display: inline-block;
    width: 1.5em;
}

.sidebar__title .toggle__btn {
    cursor: pointer;
    transition: transform .3s;
}

.sidebar__content {
    padding-top: .25em;
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.sidebar__item.open .sidebar__title .toggle__btn {
    transform: rotate(180deg);
}

.sidebar__item.open .sidebar__content {
    height: auto;
}

.title__wrapper {
    border-bottom: 1px solid #a9a9a9;
    margin-bottom: 20px;
    padding-bottom: 8px;
    margin-top: 14px;
}

.course__title {
    text-align: left;
    letter-spacing: 8px;
    font-size: 20px;
    transition: all 200ms ease;
    cursor: default;
    
}

.course__title:hover {
    color: #ffbf00;
    transform: scale(1.1);
}

.content__link {
    margin: 12px 0;
    font-size: 18px;
    font-weight: 300;
    transition: all 200ms ease;
    cursor: pointer;
    
}

.content__link:hover {
    color: #ffbf00;
    margin-left: 10px;
}

.bi-list, .bi-list1 {
    font-size: 40px;
    position: fixed;
    width: 60px;
    top: 0;
    left: 0;
    padding: 12px;
    z-index: 999999999;
    cursor: pointer;
    transition: all 200ms ease;
    margin-right: 30px;
    color: #242424;
}



.bi-list:hover {
    transform: scale(1.1);
    color: #ffbf00;
}

.bi-x-lg {
    font-size: 20px;
    position: fixed;
    width: 40px;
    bottom: 0;
    left: 170px;
    padding: 20px;
    z-index: 999999999;
    cursor: pointer;
    transition: all 200ms ease;
    margin-right: 30px
}

.bi-x-lg:hover {
    color: #ffbf00;
    transform: scale(1.05);
}

@media (max-width: 1000px) {
    .bi-x-lg {
        transform: scale(.5) !important;
        left: 190px !important;
    }

    .bi-list {
        font-size: 2rem;
    }
}