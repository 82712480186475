/* SIDE NAVBAR */

a {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

.lf-logo {
  width: 70px;
  margin-top: 10px;
  cursor: pointer;
}

.navbar__side {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.discord {
  background-color: #7289d9 !important;
  background-image: none !important;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.disc {
  color: white;
cursor: pointer;
}

.discord__logo {
 width: 20px;
 margin-right: 8px;
 cursor: pointer;
}


.logo {
  transition: all 300ms ease;
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-left: 100px;
  cursor: pointer !important;
  color: #242424;
}

.gold {
  cursor: pointer;
}

.nav__link--btn {
  padding: 10px 30px;
  background-color: #fbb034;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
  color: #242424;
  font-size: 14px;
  transition: all 500ms ease;
  cursor: pointer;
  border-radius: 8px;
  
}



.nav__links {
  padding-top: 120px;
  padding-right: 20px;
  text-transform: uppercase !important;
  letter-spacing: .75px !important;
}

.nav__link--btn {
  text-transform: uppercase !important;
  letter-spacing: .75px;
}

.nav__link {
  height: 50px;
  width: 300px;
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  transition: all 200ms ease;
  font-weight: bold;
  cursor: pointer;
 
}

.btn__right {
  background-color: #ffbf00;
  color: #242424;

}

.nav__link:hover {
  background-color: #f5f5f5;
  color: #242424;
  border: 2px solid #383838;
}

.nav__link:nth-last-of-type(1) {
  border-bottom: 1px solid #f5f5f5;
}

.nav__link--text {
  padding-left: 10px;
  font-size: 18px;
}

/* TOP NAVBAR */

.navbar__top {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  width: 100%;
  height: 80px;
  /* background-color: #5a5959; */
  color: #fff;
  /* border-bottom: 1px solid #f5f5f5; */
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.bi-list-task {
  z-index: 10000000 !important;
}

.pencil__img {
  width: 28px;
  margin-right: 4px;
  height: 28px;
}

.logo__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  transition: all 200ms ease;
}

.logo__wrapper:hover {
  transform: scale(1.05);
}

.navbar__top--container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.top__nav--links {
  display: flex;
  align-items: center;
  padding-right: 12px;
  font-weight: bold;
}


.top__nav--link--wrapper {
  margin: 0 20px;
  display: flex;
  cursor: pointer !important;
  transition: all 300ms ease;
}

.top__nav--link--wrapper:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.discord__icon {
  width: 30px;
  transition: all 300ms ease;
  margin-top: 6px;
}

.avatar__icon {
  font-size: 30px !important;
}

.dark__mode--toggle {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: -6px;
  margin-top: 4px;
}

.dark__mode--icon {
  font-size: 30px !important;
  color: gold;
}

.menu__icon {
  font-size: 40px !important;
  margin: 20px;
  display: none !important;
  cursor: pointer;
}

@media (max-width: 800px) {
  .top__nav--links {
    transform: scale(.9);
    margin-right: -60px !important;
  }

  .discord {
    display: none;
  }

  /* .menu__icon {
    display: block !important;
  } */

  .logo {
    display: none;
  }
}
