@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

}



html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  /* overflow: hidden; */
}

.video__frame {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 3px solid #ff3b3f; */
  border-radius: 8px;
  margin: 0 auto 30px auto;
  width: 100%;
  /* height: 100%; */
}

button {
  text-transform: uppercase !important;
  letter-spacing: .75px;
}

.dark {
  background-color: #242424;
  color: #fff;
}

.light {
  /* background-color: #333232; */
  /* background: linear-gradient(to top, #09203f 0%, #537895 100%) ; */
  background-color: #f1f1f1;
  background-repeat: no-repeat;
  color: #242424;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw !important;
 
  /* background-image: linear-gradient( 68.4deg,  rgba(248,182,204,1) 0.5%, rgba(192,198,230,1) 49%, rgba(225,246,240,1) 99.8% ); */
  /* background-blend-mode: multiply,multiply; */
  /* background: linear-gradient(to top, #09203f 0%, #537895 100%); */
}

input {
 cursor: text;
}

::-webkit-input-placeholder {
  color: rgb(177, 172, 172);
  font-size: 18px;
}

body {
  margin: 0;
  padding: 0;
  height: 100% ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f5f5f5;
  transition: all 300ms ease;
  width: 100%;
  
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 8px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

}

.set-height {
  min-height: 100vh !important;
}

.gold {
  color: #ff3b3f;
}

#root {
  /* height: 100%; */
}

.btn__wrapper {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  margin: 16px 0;
}

.html__btn {
  border: 1px solid #f06529 !important;
}

.html__btn:hover {
  background-color: #f06529 !important;
  color: white !important;
  
}

.css__btn {
  border: 1px solid #2965f1 !important;
}

.css__btn:hover {
  background-color: #2965f1 !important;
  color: white !important;
}

.help__btn {
  border: 1px solid #7289d9 !important;
}

.help__btn:hover {
  background-color: #7289d9 !important;
  color: white !important;
}

.ui__btn {
  border: 1px solid #b229f1 !important;
}

.ui__btn:hover {
  background-color: #b229f1 !important;
  color: white !important;
}

.js__btn {
  border: 1px solid #F0DB4F !important;
}

.js__btn:hover {
  background-color: #F0DB4F !important;
  color: white !important;
}

.api__btn {
  border: 1px solid #29f133 !important;
}

.api__btn:hover {
  background-color: #29f133 !important;
  color: white !important;
}

.react__btn {
  border: 1px solid #61dbfb !important;
}

.react__btn:hover {
  background-color: #61dbfb !important;
  color: white !important;
}

.hired__btn {
  border: 1px solid #F5BD02 !important;
}

.hired__btn:hover {
  background-color: #F5BD02 !important;
  color: white !important;
}

.technical__btn {
  border: 1px solid black !important;
}

.technical__btn:hover {
  background-color: black !important;
  color: white !important;
}

.next__lesson--btn {
  border: 1px solid #ff3b3f;
  background-color: transparent;
  padding: 10px 100px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #242424;
  transition: all 200ms ease;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.next__lesson--btn:hover {
  background-color: #ff3b3f;
}

/* .btn__wrapper:hover {
  transform: scale(1.1);
} */

.video {
  width: 100%;
  position: relative;
  /* height: 100%; */
}

.asset {
  background-color: #242424;
  color: white;
  width: 15%;
  padding: 12px 24px;
  margin-left: 2px;
  margin-bottom: 8px;
  border-radius: 12px;
  text-align: center;
  transition: all 300ms ease;
  cursor: pointer;
}

.asset:hover {
  color: #242424;
  background-color: #ecece9;
  border: 1px solid #242424;
}

.lock {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 160px !important;
  transform: translate(-50%, -50%);
}

.lock__text {
  position: absolute;
  top: 70%;
  left: 50%;
  font-size: 28px;
  text-align: center;
  transform: translate(-50%, -50%);
}

