.course__intro {
  width: 80%;
  display: flex;
  padding: 20px 12px;
  justify-content: center;
  margin: 0 auto;
}

.course__intro--title {
    text-align: center;
    font-size: 40px;
}

.course__intro--para {
    font-size: 16px;
    width: 60%;
    text-align: center;
    line-height: 1.5;
    margin: 20px auto;
}

