.header {
   text-align: center;
   background-color: transparent;
   color: #242424;
   padding: 4px;
   letter-spacing: 1.5px;
   position: fixed;
   width: 100%;
   font-size: 12px;
   transition: all 200ms ease;
}


#scroll-container {
    border-radius: 5px;
    overflow: hidden;
  }
  
  #scroll-text {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    
    -moz-animation: my-animation 5s linear infinite;
    -webkit-animation: my-animation 5s linear infinite;
    animation: my-animation 35s linear infinite;
  }
  
  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(100%); }
    to { -moz-transform: translateX(-100%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(100%); }
    to { -webkit-transform: translateX(-100%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
}
  