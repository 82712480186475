.payment {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.blur {
  filter: blur(4px);
}

.line-through {
  text-decoration: line-through;
  color: gray;
}

.bar {
  background-color: green;
  position: absolute;
  top: 5%;
  rotate: 45deg;
  padding: .25rem .5rem;
  right: -5%;
  color: white;
  border-radius: 10%;
}

.bar-text {
  font-size: .65rem;
}

.payment_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.payment_col {
  padding: 2.6rem 2.5rem;
  text-align: center;
  letter-spacing: 0.4px;
  font-weight: 500;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* display: grid;
  align-items: center; */
  position: relative;
}

.payment_col:first-child {
  background-color: #f5f5f5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.payment_col:last-child {
  background-color: #fbb034;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
  /* color: #fff; */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.payment_head {
  min-height: 150px;
  /* background-color: red; */
}

.payment_body {
  /* padding-left: 1.5rem;
  padding-right: 1.5rem; */
  min-height: 250px;
}

.plan_title {
  font-size: 1.3rem;
}

.plan_para {
  font-size: 0.82rem;
  margin-top: 2rem;
}

.plan_features {
  list-style-type: none;
  font-size: 0.87rem;
  margin-top: 2.6rem;
  margin-bottom: 2.6rem;
  text-align: left;
  display: grid;
  row-gap: 0.8rem;
  justify-content: center;
}

.plan_features li {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.plan_features li svg {
  font-size: 1.2rem;
}

.payment_col:first-child .plan_features li svg {
  fill: #ffbf00;
}

.payment_col:last-child .plan_features li svg {
  fill: #f5f5f5;
}

.plan_amount {
  font-size: 2rem;
}

.plan_amount small {
  font-size: 0.9rem;
  vertical-align: middle;
}

.plan_btn_wrapper {
  margin-top: 2.2rem;
  margin-bottom: 2.6rem;
}

.plan_btn {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: normal;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.75rem 1.4rem;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  transition: transform 300ms ease;
  pointer-events: all;
}

.plan_btn:hover {
  transform: scale(1.05);
}

.payment_col:first-child .plan_btn {
  background-color: #fbb034;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
}

.payment_col:last-child .plan_btn {
  background-color: #f5f5f5;
  /* border: 2px solid #000; */
}

.plan_offer {
  /* border: 1px solid #ffbf00; */
  border-radius: 5px;
  padding: 0.8rem 1.5rem;
}

/* .payment_col:last-child .plan_offer {
  border-color: rgba(255, 255, 255, 0.4);
} */

.plan_offer span {
  border: 2px solid #ffbf00;
  color: #ffbf00;
  border-radius: 20px;
  font-size: 0.72rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.14rem 0.7rem;
}

.payment_col:last-child .plan_offer span {
  border-color: #000;
  color: #000;
}

.plan_offer p {
  font-size: 0.8rem;
  margin-top: 0.6rem;
  font-weight: 500;
}

/* .payment_col:first-child .plan_offer p {
  color: #2780e3;
} */

@media (max-width: 768px) {
  .payment_wrapper {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }

  .payment_col {
    border-radius: 10px;
  }
}
