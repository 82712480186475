/* * {
  cursor: default;
} */

.gold {
  color: #ffbf00;
  font-weight: bold;
}

.xx {
  color: #ffbf00;
}

.home__main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8vh !important;
  max-width: 1615px;
  margin: 0 auto;

  /* background-color: #272727; */
}

.home-img {
  width: 85%;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.home-left,
.home-right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 40px;
}

.text__wrapper {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 1100px;
  margin: 0 auto;
}

/* a {
  cursor: default;
} */

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.btns__wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.home-title {
  font-size: 4rem;
}

.laptop__png {
  width: 120%;
}

.top__title {
  font-size: 50px;
  letter-spacing: 3.5px;
  text-transform: uppercase;
}

.middle__title {
  font-size: 120px;
  letter-spacing: 3.5px;
}

.bottom__title {
  margin-top: 16px;
  font-size: 50px;
  letter-spacing: 3.5px;
  text-transform: uppercase;
}

.premo__btn {
  margin: 20px;
  padding: 12px 40px;
  background-color: transparent;
  color: #242424;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 200ms ease;
  box-shadow: rgba(100, 100, 100, 0.35) 0px 5px 15px;
  border-radius: 8px;
}

.premo__btn:hover {
  transform: scale(1.1);
}

hr {
  width: 100%;
  margin-top: -100px;
  margin-bottom: 40px;
}

.home__title {
  margin-top: 20px;
  font-size: 20px;
  letter-spacing: 2.5px;
  margin-bottom: 20px;
  text-align: center;
}

.top__title {
  text-shadow: 1px 2px #383838;
}

.home-subtitle {
  font-size: 1.2rem;
  letter-spacing: 0.52px;
  line-height: 1.5;
}

.home__img {
  border-radius: 16px;
  margin-top: 40px;
  width: 40%;
}

.get__started--btn {
  padding: 10px 46px;
  background-color: #fbb034;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
  color: #242424;
  font-size: 14px;
  transition: all 500ms ease;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  border: none;
  margin-right: 18px;
}

.active__wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.verified__icon {
  color: #00ff00;
  width: 32px;
}

.active__students {
  text-align: center;
  font-weight: 300;
  font-size: 22px;
  letter-spacing: 1.5px;
}

.get__started--btn:hover {
  transform: scale(1.1);
}

.star__wrapper {
  margin-top: 30px;

  display: flex;
  flex-direction: column;
}

.star__icon {
  /* color: #ffbf00 !important; */
  color: #ffbf00 !important;

  font-size: 38px !important;
  margin: 0 4px !important;
}

.main__image {
  width: 55%;
  margin-top: 100px;
}

/* MAIN SECTION */

.main {
  margin-top: 250px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
  overflow-y: auto;
  /* background: linear-gradient(to top, #09203f 0%, #537895 100%); */
  width: 100%;
}

.main__title {
  font-size: 66px;
  margin: 60px 0;
  text-align: center;
  padding: 20px;
  color: #242424;
  border-bottom: 2px solid #ffbf00;
}

.main__content {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin-top: 80px;
  padding: 20px 0;
}

.main__content--right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main__content--title {
  font-size: 36px;
  color: #242424;
}

.main__content--para {
  width: 80%;
  line-height: 1.5;
  margin-top: 20px;
  font-size: 22px;
}

.main__content--left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main__content--img {
  /* width: 400px;
  height: 400px; */
  width: 80%;
  margin-left: 20px;
  transition: all 400ms ease;
}

.main__content--img:hover {
  transform: scale(1.1);
}

/* REQS */

.requirements__container {
  width: 80%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px 20px;
  /* background-color: rgb(36, 35, 35); */
}
.requirements__container:hover {
  transform: scale(1.1);
}

.req__figure {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid black;
}

.req__icon {
  font-size: 38px !important;
  margin-right: 10px;
  color: #ffbf00;
}

.requirement__title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
}

.requirement__value {
  font-size: 20px;
  font-weight: bold;
}

/* TESTIMONIALS */

.testimonials {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  margin: 140px 0;
}

.testimonials-header {
  text-align: center;
  margin-bottom: 100px;
  font-size: 80px;
}

.test-wrapper {
  display: flex;
}

.test-container {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 28px;
  margin: 0 20px;
  border-radius: 8px;
  max-width: 33%;
  transition: all 0.3s ease;
}

.test-container:hover {
  transform: scale(1.1);
}

.test-title {
  margin-bottom: 12px;
}

.test-para {
  line-height: 1.25;
  margin-bottom: 12px;
}

/* PREMIUM */

.premium__ad {
  height: 40vh;
  background-color: #fbb034;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.premium__subtitle {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 20px;
  text-align: center;
}

.premium__title {
  font-size: 80px;
  text-align: center;
}

/* COMMUNITY */

.community {
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(to top, #09203f 0%, #537895 100%); */
}

.hr2 {
  width: 98%;
  margin: 30px auto;
  display: none;
}

.hr3 {
  margin: 60px auto;
  width: 70%;
  border: 1px solid #ffbf00;
}

.community__title {
  margin: 20px 0;
  font-size: 66px;
  text-align: center;
  color: #242424;
}

.community__wrapper {
  max-width: 1000px;
  margin: 40px auto;
}

.community__bubble {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.btn2 {
  margin-left: 18px;
}

.community__subtitle {
  font-size: 28px;
  letter-spacing: 1.2px;
  text-align: center;
}

.community__para {
  margin: 15px 0;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}

@media (max-width: 1050px) {
  .home-right {
    display: none;
  }

  .home-left {
    width: 100%;
    display: flex;
  }
  .home-img {
    display: none;
  }

  .home__subtitle {
    padding: 0 12px;
  }
}

.discord-home {
  background-color: #7289d9 !important;
  background-image: none !important;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: none;
}

@media (max-width: 800px) {
  .discord-home {
    display: block !important;
    display: flex !important;
    width: 50%;
    margin-top: 20px;
  }

  .btns__wrapper {
    display: none;
  }

  .home-title {
    font-size: 3.5rem;
    margin-top: 20px;
  }

  .premium__ad {
    display: none !important;
  }

  .premium__title {
    font-size: 2rem;
  }

  .main__image {
    display: none;
  }

  .top__title,
  .bottom__title {
    font-size: 32px;
  }

  .middle__title {
    font-size: 60px;
  }

  .requirements__container {
    display: none;
  }

  .premium__title {
    font-size: 40px;
  }

  .main {
    margin-top: -140px;
  }

  .premo__btn {
    transform: scale(0.7);
  }

  .get__started--btn {
    transform: scale(0.8);
  }
}

@media (max-width: 900px) {
  .btn2 {
    /* margin: 30px 0; */
  }

  .main {
    margin-top: -80px;
  }

  .premium__btn {
    font-size: 12px !important;
  }

  .main__content--img {
    display: none;
  }
}

@media (max-width: 1300px) {
  .testimonials {
    display: none;
  }

  .premium__title {
    font-size: 4rem;
  }
}

@media (max-width: 650px) {
  .main {
    margin-top: -170px;
  }

  .text__wrapper {
    margin-top: -30px;
  }
}

@media (max-width: 1050px) {
  hr {
    display: none;
  }

  .main {
    margin-top: -50px;
  }

  .main__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .main__title {
    font-size: 40px;
    margin-top: 180px;
    margin-bottom: 20px;
  }

  .main__content--left,
  .main__content--right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px 0;
  }
}

@media (max-width: 1070px) {
  .text__wrapper {
    height: 80%;
  }
}
