.account-form {
    display: block;
    max-width: unset;
    font-size: 16px;
    font-weight: 400;
    height: 100%;
    color: #242424;
}

.account__container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #242424;
}

.account__page {
height: 100% !important;
}

.account__header {
    margin-top: 20px;
    font-size: 40px;
    border-bottom: 1px solid gray;
    padding-bottom: 26px;
    text-align: center;
    color: #242424;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    
}

.account__item {
    border-bottom: 1px solid gray;
    margin: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    color: #242424;
    font-size: 22px;
   /* font-weight: bold; */
    
}

.account__item .account__item--value {
    margin-bottom: 10px;
}

.account__item--type {
    margin-bottom: 8px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
}

.account__btn {
    position: absolute;
    right: 0;
    bottom: 13px;
    width: 190px;
    height: 40px;
    border-radius: 8px;
    border: none;
    color: #242424;
    background-color: transparent;
    font-weight: bold;
    transition: all 300ms ease;
    cursor: pointer;
    text-shadow: 0 0;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
}


.account__page .account__btn img {
    width: 12px;
    margin-right: 3px;
}


.accform-two-fields {
    display: flex;
}

.accform-two-fields input[type="text"] {
    width: 40%;
    height: 46px;
    border: 1px solid lightgray;
    border-radius: 8px;
    font-size: 22px;
    background-color: transparent;
    color: #242424;
}
.accform-two-fields input[type="text"]:first-child {
    border-right: 1px solid lightgray;
    margin-right: 10px;
    color: #242424;
}
.accform-two-fields input[type="password"] {
    width: 40%;
    height: 46px;
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid lightgray;
}

@media (max-width: 600px) {
    .accform-two-fields {
        /* width: 80%; */
    }
    input {
        font-size: 12px !important;
        padding: 2px;
        /* width: 100% !important; */
    }

    .account__item {
        height: 80%;
    }

    .account__btn {
        /* display: none; */
    }

}
