.auth {
    height: 100%;
}

form {
    font-weight: bold;
    max-width: 1200px;
    margin: 32px auto 0 auto;
    width: 100%;
    font-size: 20px;
    border-radius: 2px;
    transition: all 200ms ease;
    display: flex;
    justify-content: space-between;

}

.form__title {
    text-align: center;
    margin-top: 20px;
    font-size: 40px;
    letter-spacing: 1.2px;
    margin-bottom: -10px;
    color: #242424;
}

.form__left {
    width: 50%;
}

.form__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}

.form__title--right {
    font-size: 70px;
    text-align: center;
}

.form__para--right {
    text-align: center;
    margin-top: 20px;
    padding: 0 20px;
    
}

.form__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start !important;
    margin: 20px 0;
}

.input {
    height: 40px;
    width: 100%;
    font-size: 18px;
    padding: 4px;
}

@media (max-width: 1000px) {
    .input {
        margin-left: -2px !important;
    }
}

label {
    margin-bottom: 3px;
    font-size: 24px;
    letter-spacing: 1px;
}

.form__submit {
    background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
    color: #242424;
    font-weight: bold;
    max-width: 240px;
    width: 100%;
    padding: 12px 24px;
    font-size: 20px;
    border-radius: 2px;
    transition: all 200ms ease;
    cursor: pointer;
    border-radius: 8px;  
    border: none;  
}

.form__submit:hover {
    transform: scale(1.1);
}

.form__submit:active {
    border-color: #ffbf00;
    color: #ffbf00;
}

.wave {
    position: absolute;
    bottom: 1px;
    z-index: -1;
}

.error__message {
    margin-bottom: 12px;
}

@media (max-width: 800px) {
    .auth {
        height: 100%;
    }

    .wave {
        display: none;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form__right {
        margin-top: 40px;
        width: 100%;
    }

    .form__title--right {
        font-size: 40px;
    }

    .form__left {
        width: 100%;
        text-align: center;
        margin: 0 20px;
    }

    .input {
       width: 70%;
       margin: 0 auto;
    }

    .premium__wrapper {
        transform: scale(.7);
    }

    .or {
        text-align: center;
        font-size: 40px !important;
    }

    .premium__btn {
        width: 100% !important;
        font-size: .9rem !important;
    }
}


